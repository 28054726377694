import React, { useState } from 'react';
import {
  Box,
  useColorModeValue,
  Text,
  VStack,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Select,
  Icon,
} from '@chakra-ui/react';
import { FaRegEdit, FaTrashAlt, FaRegFileAlt } from 'react-icons/fa';

function DatasetCard({ id, title, format, shape, lastUpdated, onEdit, onDelete }) {
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [newTitle, setNewTitle] = useState(title);
  const [newFormat, setNewFormat] = useState(format);

  const handleEdit = () => {
    onEdit(id, newTitle, newFormat);
    onEditClose();
  };

  const handleConfirmDelete = () => {
    onDelete(id);
    onDeleteClose();
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Box
      bg={useColorModeValue('brand.modalCard', 'brand.modalCardDark')}
      p={{ base: 4, md: 6 }}
      rounded="xl"
      boxShadow="sm"
      borderWidth="2px"
      transition="transform 0.2s, box-shadow 0.2s"
      _hover={{
        transform: 'scale(1.03)',
        boxShadow: 'xl',
      }}
      display="flex"
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      justifyContent="space-between"
      minW="full"
      textAlign="left"
    >
      <HStack spacing={4} alignItems="center">
        <Icon as={FaRegFileAlt} boxSize={8} color={useColorModeValue('brand.iconPrimary', 'brand.iconPrimaryDark')} />
        <Text
          flex="1"
          color={useColorModeValue('brand.textPrimary', 'brand.textPrimaryDark')}
          fontSize="lg"
          fontWeight="bold"
          textAlign="left"
        >
          {title}
        </Text>
      </HStack>

      <VStack flex="1" alignItems="flex-end" spacing={2} mt={{ base: 4, sm: 0 }}>
        <Text fontSize={12}>
          <strong>Shape:</strong> {shape}
        </Text>
        <Text fontSize={12}>
          <strong>Last Updated:</strong> {formatDate(lastUpdated)}
        </Text>
        <Text fontSize={12}>
          <strong>Type:</strong> {format}
        </Text>
        <HStack spacing={2}>
          <Button
            variant="ghost"
            colorScheme="blue"
            size="sm"
            onClick={onEditOpen}
            leftIcon={<Icon as={FaRegEdit} />}
          >
            Edit
          </Button>
          <Button
            variant="ghost"
            colorScheme="red"
            size="sm"
            onClick={onDeleteOpen}
            leftIcon={<Icon as={FaTrashAlt} />}
          >
            Delete
          </Button>
        </HStack>
      </VStack>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Dataset</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Input value={newTitle} onChange={(e) => setNewTitle(e.target.value)} placeholder="File Name" />
              <Select value={newFormat} onChange={(e) => setNewFormat(e.target.value)}>
                <option value="alpaca">Alpaca</option>
                <option value="sharegpt">ShareGPT</option>
                <option value="completion">Completion</option>
              </Select>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onEditClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleEdit}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalBody>Do you really want to delete this item? This action cannot be undone.</ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onDeleteClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default DatasetCard;