import { Container, Heading, Flex, SimpleGrid, Alert, AlertIcon, CloseButton } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ButtonUpload from "../../components/dataset/button";
import DatasetCard from "../../components/dataset/datasetCard";
import { getAllDatasetsAsync, deleteDatasetAsync } from "../../features/dataset/datasetAction";
import { useDispatch, useSelector } from "react-redux";

function Dataset() {
  const dispatch = useDispatch();
  const datasets = useSelector((state) => state.datasets.datasets);
  const [successMessage, setSuccessMessage] = useState(null); // State variable for success message

  useEffect(() => {
    dispatch(getAllDatasetsAsync())
      .unwrap()
      .catch((error) => {
        console.error("Error fetching datasets:", error);
      });
  }, [dispatch]);

  const handleEdit = (id, newTitle, newFormat) => {
  };

  const handleDelete = (uuid) => {
    dispatch(deleteDatasetAsync(uuid))
      .unwrap()
      .then(() => {
        setSuccessMessage("Dataset deleted successfully!"); 
        setTimeout(() => {
          setSuccessMessage(null);
        }, 4000); 
      })
      .catch((error) => {
        console.error("Error deleting dataset:", error);
      });
  };

  return (
    <Container maxW="7xl" mt={0} px={4}>
      <Flex direction="column" mb={6}>
        <Heading as="h1" size="xl" py={2}>
          Dataset
        </Heading>
        <Flex alignItems="center" gap={4}>
          <Heading fontWeight={500} as="h2" size="md" py={0}>
            Upload your training dataset.
          </Heading>
          <ButtonUpload />
        </Flex>
      </Flex>

      {successMessage && (
        <Alert status="success" mb={4} borderRadius="md">
          <AlertIcon />
          {successMessage}
          <CloseButton
            onClick={() => setSuccessMessage(null)}
            position="absolute"
            right="8px"
            top="8px"
          />
        </Alert>
      )}

      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacingX={5} spacingY={6} pt={2}>
        {datasets.map((dataset) => (
          <DatasetCard
            key={dataset._id}
            id={dataset.uuid}
            title={dataset.name}
            format={dataset.type}
            shape={`Rows: ${dataset?.metadata?.rows}`}
            lastUpdated={dataset.updatedAt}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        ))}
      </SimpleGrid>
    </Container>
  );
}

export default Dataset;
