import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  SimpleGrid,
  Text,
  Flex,
  Heading,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Divider,
  useColorModeValue,
  Badge,
  Icon,
  Tooltip,
  StatArrow,
  Tag,
  Progress,
  Spacer,
  HStack,
} from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  Filler,
} from "chart.js";
import {
  FiSettings,
  FiCpu,
  FiBookOpen,
  FiZap,
  FiDroplet,
  FiInfo,
  FiDatabase,
  FiClock,
  FiActivity,
  FiTrendingUp,
  FiHash,
  FiKey,
  FiFolder,
  FiFileText,
} from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchFineTuningJobAsync } from "../../features/finetune/finetuneAction";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  Filler,
  zoomPlugin
);

const Dashboard = () => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const jobDetails = useSelector((state) =>
    state.fineTuning.jobs.find((job) => job.uuid === jobId)
  );
  const loading = useSelector((state) => state.fineTuning.loading);

  useEffect(() => {
    if (jobId) {
      dispatch(fetchFineTuningJobAsync(jobId));
    }
  }, [dispatch, jobId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!loading && jobId) {
        dispatch(fetchFineTuningJobAsync(jobId));
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch, loading, jobId]);

  const chartRef = useRef(null);
  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const data = jobDetails || {
    name: "None",
    key: "None",
    model: {
      name: "None",
      path: "None",
    },
    config: {
      base_model: "None",
      learning_rate: "None",
      num_epochs: "None",
      method: "None",
      warmup_steps: "None",
      evals_per_epoch: "None",
      lora_r: "None",
      lora_alpha: "None",
      lora_dropout: "None",
      gradient_accumulation_steps: "None",
      micro_batch_size: "None",
      load_in_4bit: "None",
    },
    dataset: {
      name: "None",
      path: "None",
      type: "None",
      format: "None",
    },
    training_metrics: [],
    final_metrics: [
      {
        train_runtime: "None",
        train_samples_per_second: "None",
        train_steps_per_second: "None",
        total_flos: "None",
        train_loss: "None",
        epoch: "None",
        step: "None",
        timestamp: "None",
      },
    ],
    status: "None",
  };

  const truncate = (str, n) => {
    return str && str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const chartData = {
    labels: data?.training_metrics.map((metric) => metric.step) || [],
    datasets: [
      {
        label: "Loss",
        data: data?.training_metrics.map((metric) => metric.loss) || [],
        borderColor: "rgba(0, 70, 127, 1)",
        backgroundColor: "rgba(0, 70, 127, 0.1)",
        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
      {
        label: "Gradient Norm",
        data: data?.training_metrics.map((metric) => metric.grad_norm) || [],
        borderColor: "rgba(180, 180, 180, 1)",
        backgroundColor: "rgba(180, 180, 180, 0.1)",
        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 6,
          color: "rgba(0, 0, 0, 0.5)",
        },
        title: {
          display: true,
          text: "Step",
        },
      },
      y: {
        grid: {
          color: "rgba(0, 0, 0, 0.05)",
        },
        ticks: {
          maxTicksLimit: 5,
          color: "rgba(0, 0, 0, 0.5)",
        },
        title: {
          display: true,
          text: "Value",
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
  };

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const accentColor = useColorModeValue("blue.500", "blue.300");

  const InfoItem = ({ icon, label, value, tooltip }) => {
    const bgColor = useColorModeValue("gray.50", "gray.700");
    const borderColor = useColorModeValue("gray.200", "gray.600");

    return (
      <Tooltip label={tooltip} hasArrow placement="top">
        <Box
          p={3}
          bg={bgColor}
          borderRadius="md"
          borderWidth="1px"
          borderColor={borderColor}
          transition="all 0.3s"
          _hover={{ transform: "translateY(-2px)" }}
          height="100%"
        >
          <Flex align="center" height="100%">
            <Icon
              as={icon}
              color="blue.500"
              boxSize={5}
              mr={3}
              flexShrink={0}
            />
            <VStack align="start" spacing={0} width="100%">
              <Text fontSize="sm" fontWeight="medium" color="gray.500">
                {label}
              </Text>
              <Text fontWeight="bold" fontSize="sm" isTruncated>
                {value || "None"}
              </Text>
            </VStack>
          </Flex>
        </Box>
      </Tooltip>
    );
  };

  const MetricCard = ({ icon, label, value, helpText, progress, trend }) => {
    const cardBg = useColorModeValue("white", "gray.700");
    const textColor = useColorModeValue("gray.600", "gray.200");

    return (
      <Box
        bg={cardBg}
        p={5}
        borderRadius="lg"
        boxShadow="sm"
        border={"solid 1px"}
        transition="all 0.3s"
      >
        <Stat>
          <Flex align="center" mb={2}>
            <Icon as={icon} color={accentColor} boxSize={6} mr={2} />
            <StatLabel fontSize="lg" fontWeight="medium">
              {label}
            </StatLabel>
          </Flex>
          <StatNumber fontSize="2xl" fontWeight="bold">
            {typeof value === "number" ? value.toFixed(4) : value}
          </StatNumber>
          {helpText && (
            <StatHelpText color={textColor}>{helpText}</StatHelpText>
          )}
          {progress && (
            <Progress value={progress} colorScheme="blue" size="sm" mt={2} />
          )}
          {trend && (
            <StatHelpText>
              <StatArrow type={trend > 0 ? "increase" : "decrease"} />
              {Math.abs(trend).toFixed(2)}%
            </StatHelpText>
          )}
        </Stat>
      </Box>
    );
  };

  const ConfigItem = ({ icon, label, value, tooltip }) => (
    <Tooltip label={tooltip} hasArrow placement="top">
      <VStack
        align="start"
        p={3}
        bg={useColorModeValue("gray.50", "gray.700")}
        borderRadius="md"
        transition="all 0.3s"
        _hover={{ bg: useColorModeValue("gray.100", "gray.600") }}
      >
        <Flex align="center">
          <Icon as={icon} color={accentColor} boxSize={5} mr={2} />
          <Text fontWeight="medium" fontSize="sm">
            {label}
          </Text>
        </Flex>
        <Text fontWeight="bold" fontSize="md">
          {value || "None"}
        </Text>
      </VStack>
    </Tooltip>
  );

  const SectionCard = ({ title, icon, children }) => {
    const bgColor = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue("gray.300", "gray.700");

    return (
      <Box
        bg={bgColor}
        p={6}
        borderRadius="lg"
        border={"solid 1px"}
        borderWidth="1px"
        borderColor={borderColor}
      >
        <Flex align="center" mb={4}>
          <Icon as={icon} color="blue.500" boxSize={6} mr={2} />
          <Heading size="md">{title}</Heading>
        </Flex>
        <Divider mb={4} />
        {children}
      </Box>
    );
  };

  const finalMetrics = data?.final_metrics[0] || {};

  return (
    <Box bg={bgColor} minH="100vh" py={8}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Flex justify="space-between" align="center">
            <Heading size="xl">Finetuned Model Details</Heading>
            <Badge
              colorScheme={data.status === "succeeded" ? "green" : "yellow"}
              fontSize="md"
              px={3}
              py={1}
              borderRadius="full"
            >
              {data?.status.toUpperCase()}
            </Badge>
          </Flex>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <SectionCard title="Model Details" icon={FiCpu}>
              <SimpleGrid columns={{ base: 1, sm: 1 }} spacing={4}>
                <InfoItem
                  icon={FiHash}
                  label="Name"
                  value={data?.trained_model?.name}
                  tooltip="Unique identifier for this fine-tuning job"
                />
                <InfoItem
                  icon={FiKey}
                  label="Key"
                  value={data?.name}
                  tooltip="API key for accessing this fine-tuned model"
                />
                <InfoItem
                  icon={FiCpu}
                  label="Model"
                  value={data?.model?.name}
                  tooltip="Base model used for fine-tuning"
                />
                <InfoItem
                  icon={FiFolder}
                  label="Dataset"
                  value={data?.dataset?.name}
                  tooltip="Path to the base model"
                />
              </SimpleGrid>
              <Box mt={4}>
                <HStack>
                  <Badge
                    colorScheme={data.status === "succeeded" ? "blue" : "yellow"}
                  >
                    {data?.dataset?.type.toUpperCase()}
                  </Badge>
                  <Spacer />
                  <Badge
                    colorScheme={data.status === "succeeded" ? "blue" : "yellow"}
                  >
                    {data?.dataset?.format.toUpperCase()}
                  </Badge>
                </HStack>
              </Box>
            </SectionCard>

            <SectionCard title="Training Metrics" icon={FiInfo}>
              <Box height="400px">
                <Line data={chartData} options={chartOptions} />
              </Box>
            </SectionCard>
          </SimpleGrid>

          <SectionCard title="Configuration" icon={FiSettings}>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
              <ConfigItem
                icon={FiCpu}
                label="Base Model"
                value={data?.config?.base_model}
                tooltip="The foundation model used for fine-tuning"
              />
              <ConfigItem
                icon={FiZap}
                label="Learning Rate"
                value={data?.config?.learning_rate}
                tooltip="Step size at each iteration while moving toward a minimum of the loss function"
              />
              <ConfigItem
                icon={FiBookOpen}
                label="Num Epochs"
                value={data?.config?.num_epochs}
                tooltip="Number of complete passes through the training dataset"
              />
              <ConfigItem
                icon={FiSettings}
                label="Method"
                value={data?.config?.method}
                tooltip="Fine-tuning method used"
              />
              <ConfigItem
                icon={FiZap}
                label="Warmup Steps"
                value={data?.config?.warmup_steps}
                tooltip="Number of steps for the warmup phase of learning rate scheduler"
              />
              <ConfigItem
                icon={FiBookOpen}
                label="Evals per Epoch"
                value={data?.config?.evals_per_epoch}
                tooltip="Number of evaluations performed per epoch"
              />
              <ConfigItem
                icon={FiDroplet}
                label="Lora R"
                value={data?.config?.lora_r}
                tooltip="Rank of the LoRA update matrices"
              />
              <ConfigItem
                icon={FiDroplet}
                label="Lora Alpha"
                value={data?.config?.lora_alpha}
                tooltip="LoRA scaling factor"
              />
              <ConfigItem
                icon={FiDroplet}
                label="Lora Dropout"
                value={data?.config?.lora_dropout}
                tooltip="Dropout probability for LoRA layers"
              />
              <ConfigItem
                icon={FiZap}
                label="Gradient Accumulation Steps"
                value={data?.config?.gradient_accumulation_steps}
                tooltip="Number of steps to accumulate gradient before performing a backward/update pass"
              />
              <ConfigItem
                icon={FiCpu}
                label="Micro Batch Size"
                value={data?.config?.micro_batch_size}
                tooltip="Number of samples processed in each mini-batch"
              />
              <ConfigItem
                icon={FiCpu}
                label="Load in 4bit"
                value={
                  <Tag
                    size="sm"
                    colorScheme={data.config?.load_in_4bit ? "green" : "red"}
                  >
                    {data.config?.load_in_4bit ? "Yes" : "No"}
                  </Tag>
                }
                tooltip="Whether the model is loaded in 4-bit precision"
              />
            </SimpleGrid>
          </SectionCard>

          <SectionCard title="Final Metrics" icon={FiActivity}>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={6}>
              <MetricCard
                icon={FiClock}
                label="Train Runtime"
                value={finalMetrics?.train_runtime}
                helpText="Total training time (seconds)"
                progress={
                  finalMetrics?.train_runtime !== "None"
                    ? (finalMetrics?.train_runtime / 3600) * 100
                    : 0
                }
              />
              <MetricCard
                icon={FiZap}
                label="Samples/Second"
                value={finalMetrics?.train_samples_per_second}
                helpText="Processing speed"
                trend={
                  finalMetrics?.train_samples_per_second !== "None" ? 10 : 0
                }
              />
              <MetricCard
                icon={FiTrendingUp}
                label="Train Steps/Second"
                value={finalMetrics?.train_steps_per_second}
                helpText="Training speed"
                trend={finalMetrics?.train_steps_per_second !== "None" ? 5 : 0}
              />
              <MetricCard
                icon={FiCpu}
                label="Total FLOPs"
                value={
                  finalMetrics?.total_flos !== "None"
                    ? (finalMetrics?.total_flos / 1e12).toFixed(2) + " T"
                    : "None"
                }
                helpText="Computational complexity (Teraflops)"
              />
              <MetricCard
                icon={FiActivity}
                label="Final Train Loss"
                value={finalMetrics?.train_loss}
                helpText="Lower is better"
                progress={
                  finalMetrics?.train_loss !== "None"
                    ? 100 - (finalMetrics?.train_loss / 2) * 100
                    : 0
                }
              />
              <MetricCard
                icon={FiClock}
                label="Epoch"
                value={finalMetrics?.epoch}
                helpText={`Out of ${data.config.num_epochs} epochs`}
                progress={
                  finalMetrics?.epoch !== "None"
                    ? (finalMetrics?.epoch / data?.config.num_epochs) * 100
                    : 0
                }
              />
              <MetricCard icon={FiClock} label="Tokens" value={data?.total_num_tokens} />
              <MetricCard
                icon={FiClock}
                label="Hardware"
                value={data?.tier?.configuration?.cpu?.description}
              />
            </SimpleGrid>
          </SectionCard>
        </VStack>
      </Container>
    </Box>
  );
};

export default Dashboard;
