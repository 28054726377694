import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Select,
  VStack,
  Collapse,
  Box,
  useDisclosure,
  HStack,
  useToast
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { getModelsAsync } from '../../features/models/modelActions';
import { getAllDatasetsAsync } from '../../features/dataset/datasetAction';
import { createFineTuningJobAsync } from '../../features/finetune/finetuneAction';

export default function BasicModal() {
  const { isOpen, onToggle } = useDisclosure();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast(); 
  const { models } = useSelector(state => state.model);
  const { datasets } = useSelector(state => state.datasets);

  const [formData, setFormData] = useState({
    model: '',
    modelPath: '',
    modelName: '',
    dataPath: '',
    learningRate: 0.0002,
    numEpochs: 2,
    method: 'lora',
    warmupSteps: 10,
    evalsPerEpoch: 4,
    dataType: 'alpaca',
    gradientAccumulationSteps: 8,
    microBatchSize: 1,
    dsType: 'json'
  });

  useEffect(() => {
    dispatch(getModelsAsync({ page: 1, size: 10 }));
    dispatch(getAllDatasetsAsync());
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await dispatch(createFineTuningJobAsync(formData)).unwrap();
      setOpen(false);
  
      if (result && result.uuid) {
        toast({
          title: "Initializing",
          description: "Job started successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom"
        });
      } else {
        toast({
          title: "Error",
          description: "There was an error starting the job.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom"
        });
      }
    } catch (error) {
      setOpen(false);
      toast({
        title: "Error",
        description: error.message || "There was an error processing your request.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom"
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleModelSelect = (event) => {
    const selectedModel = models.find(model => model.uuid === event.target.value);
    setFormData(prevState => ({
      ...prevState,
      model: selectedModel.uuid,
      modelPath: selectedModel.path,
      modelName: selectedModel.name
    }));
  };

  const handleDatasetSelect = (event) => {
    const selectedDataset = datasets.find(dataset => dataset.uuid === event.target.value);
    setFormData(prevState => ({
      ...prevState,
      dataPath: selectedDataset.path
    }));
  };

  return (
    <>
      <Button
        leftIcon={<AddIcon />}
        bg="#4E4FEB"
        onClick={() => setOpen(true)}
        mt={4}
        mb={4}
        size="lg"
        color="white"
        _hover={{
          bg: "brand.secondary",
        }}
      >
        Create Finetune Job
      </Button>

      <Modal size={'2xl'} isOpen={open} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box maxHeight="500px" overflowY="auto">
              <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                  <FormControl>
                    <FormLabel>Model</FormLabel>
                    <Select name="model" onChange={handleModelSelect} placeholder="Select Model">
                      {models.map(model => (
                        <option key={model.uuid} value={model.uuid}>{model.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Dataset</FormLabel>
                    <Select name="dataPath" onChange={handleDatasetSelect} placeholder="Select Dataset" required>
                      {datasets.map(dataset => (
                        <option key={dataset.uuid} value={dataset.uuid}>{dataset.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <Button onClick={onToggle} variant="ghost" alignSelf="start">
                    Advanced Options
                  </Button>
                  <Collapse in={isOpen} animateOpacity>
                    <VStack spacing={4}>
                      <HStack>
                      <FormControl>
                        <FormLabel>Learning Rate</FormLabel>
                        <Input name="learningRate" type="number" placeholder="0.0002" value={formData.learningRate} onChange={handleInputChange} />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Number of Epochs</FormLabel>
                        <Input name="numEpochs" type="number" placeholder="2" value={formData.numEpochs} onChange={handleInputChange} />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Warmup Steps</FormLabel>
                        <Input name="warmupSteps" type="number" placeholder="10" value={formData.warmupSteps} onChange={handleInputChange} />
                      </FormControl>
                      </HStack>
                      <HStack>
                      <FormControl>
                        <FormLabel>Evaluations Per Epoch</FormLabel>
                        <Input name="evalsPerEpoch" type="number" placeholder="4" value={formData.evalsPerEpoch} onChange={handleInputChange} />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Gradient Steps</FormLabel>
                        <Input name="gradientAccumulationSteps" type="number" placeholder="8" value={formData.gradientAccumulationSteps} onChange={handleInputChange} />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Micro Batch Size</FormLabel>
                        <Input name="microBatchSize" type="number" placeholder="1" value={formData.microBatchSize} onChange={handleInputChange} />
                      </FormControl>
                      </HStack>
                      <FormControl>
                        <FormLabel>Data Type</FormLabel>
                        <Select name="dataType" onChange={handleInputChange} defaultValue="alpaca">
                          <option value="alpaca">Alpaca</option>
                          <option value="llama">Llama</option>
                        </Select>
                      </FormControl>
                    </VStack>
                  </Collapse>
                  <Button type="submit" w={"100%"} variant="solid" bg={'brand.primary'} color='white'>
                    Create Finetune Job
                  </Button>
                </VStack>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
