import React, { useEffect } from "react";

import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
  VStack,
  HStack,
  Tag,
  Collapse,
  Tooltip,
  useDisclosure,
  IconButton,
  Button,
  Progress,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import { BiGitRepoForked } from "react-icons/bi";
import { FiGithub } from "react-icons/fi";
import { ChevronDown, ChevronUp } from "lucide-react";
import { MdOutlineViewTimeline } from "react-icons/md";
import { GiUpgrade } from "react-icons/gi";
import { Link as RouterLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import BasicModal from "../../components/finetune/button";

import { fetchAllFineTuningJobsAsync } from "../../features/finetune/finetuneAction";

const FinetuneCard = ({ data }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const { isOpen, onToggle } = useDisclosure();

  const getStatusColor = (status) => {
    switch (status) {
      case "failed":
        return "red";
      case "running":
        return "green";
      default:
        return "orange";
    }
  };

  const statusColor = getStatusColor(data.status);

  return (
    <Box
      py={2}
      px={[2, 4]}
      mt={0}
      rounded="xl"
      borderWidth="1px"
      bg={useColorModeValue("white", "gray.800")}
      borderColor={useColorModeValue("gray.100", "gray.700")}
      _hover={{
        shadow: "lg",
        textDecoration: "none",
      }}
    >
      <VStack overflow="hidden" align="start" spacing={1}>
        <VStack spacing={1} align="start" w="100%">
          <Flex justifyContent="space-between" width="100%">
            <Tooltip hasArrow label="Github link" placement="top">
              <HStack cursor="pointer">
                <Icon as={GiUpgrade} boxSize="0.9em" mt="1px" />
                <Text fontSize="sm" noOfLines={1} fontWeight="600" align="left">
                  {data.model.name}
                </Text>
              </HStack>
            </Tooltip>
            <HStack>
              <Flex _hover={{ color: "blue.500" }} alignItems="center">
                <Icon as={MdOutlineViewTimeline} boxSize="0.9em" mt="1px" />
                <Box as="span" ml="1" fontSize="sm"></Box>
              </Flex>
              <IconButton
                icon={
                  isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />
                }
                variant="ghost"
                onClick={onToggle}
              />
            </HStack>
          </Flex>
          <Flex justifyContent="space-between" width="100%">
            <Box>
              <HStack spacing="1">
                <Tag size="sm" colorScheme={statusColor}>
                  <Text fontSize={["0.55rem", "inherit", "inherit"]}>
                    {data.status || "Pending"}
                  </Text>
                </Tag>
              </HStack>
            </Box>
          </Flex>
        </VStack>
        <Box>
          <Text color="gray.500" fontSize="xs" noOfLines={2} textAlign="left">
            {data.name || "No description provided."}
          </Text>
          <Collapse in={isOpen} animateOpacity>
            <Box py={4}>
              <VStack spacing={1} align="stretch">
                <Heading fontSize="lg" color={textColor}>
                  Details
                </Heading>
                <HStack>
                  <Text as="b" fontSize={12} color={textColor}>
                    Dataset:
                  </Text>
                  <Text fontSize={12} color={textColor}>
                    {data?.dataset?.name || "Unknown"}
                  </Text>
                </HStack>
                <HStack>
                  <Text as="b" fontSize={12} color={textColor}>
                    Learning Rate:
                  </Text>
                  <Text fontSize={12} color={textColor}>
                    {data.config.learning_rate || "Unknown"}
                  </Text>
                </HStack>
                <Flex justify="space-between" align="center"></Flex>
                <Button
                  mt={2}
                  size="sm"
                  border="1px"
                  borderColor="brand.primary"
                  as={RouterLink}
                  to={`/details/${data.uuid}`}
                >
                  View Details
                </Button>
              </VStack>
            </Box>
          </Collapse>
        </Box>
      </VStack>
    </Box>
  );
};

export default FinetuneCard;
